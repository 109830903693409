@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// Firebae UI overrides
.signInViaEmailForm {
	width: 300px;
	margin: 0 auto;

	input[type=email] {
		width: 100%;
		padding: 10px;
		font-size: 16px;
		border: 1px solid #999999;
	}

	button {
		width: 100%;
		padding: 10px;
		background-color: #4784EF;
		border: 1px solid #4784EF;
		color: white;
		cursor: pointer;
	}

	.or {
		text-align: center;
		color: #aaaaaa;
		margin: 30px;
	}
}

#firebaseui_container {
	& > .firebaseui-container {
		background-color: transparent;
	}

	& > .mdl-card {
		// border: 1px solid #cccccc;
		// border-radius: 15px;
		// box-shadow: 0 5px 30px rgba(0,0,0,0.05);
		border: 0;
		box-shadow: none;
	}

	.firebaseui-label::after {
		background-color: #4784EF;
	}

	.firebaseui-idp-button {
		border-radius: 8px;
		box-shadow: none;
	}

	.firebaseui-idp-google {
		border: 1px solid #cccccc;
	}
}

.dark {
	.firebaseui-subtitle,
	.firebaseui-title,
	.firebaseui-country-selector,
	.firebaseui-input,
	.firebaseui-label {
		color: white;
	}

	.firebaseui-country-selector,
	.firebaseui-input {
		border-bottom-color: #999999 !important;
	}
}

.uppy-DragDrop-container {
	outline: none;
}

.uppy-DragDrop-label {
	font-size: 0.9em !important;
}

.contains-task-list {
	li.task-list-item {
		input[type=checkbox] {
			margin-top: 0;
			margin-bottom: 0.2rem;
			margin-right: 0.2rem;
		}
	}
}

.featureModalContainer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	overflow: auto;

	.featureModalBody {
		position: relative;
		z-index: 100;
	}

	.featureModalBackdrop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,0.4);
		z-index: 90;
	}
}

.dark .featureModalContainer .featureModalBackdrop {
	background: rgba(0,0,0,0.7);
}

.notificationsContainer {
	z-index: 200;
}

.dark {
	.prose {
		strong {
			@apply text-white;
		}

		pre {
			@apply bg-gray-700;
		}
	}

	.uppy-DragDrop-container {
		@apply bg-gray-700 border-gray-500;
	}
}